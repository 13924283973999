import { Table } from 'antd';

/**
 * wtdc翻页转换成antd
 * @param {*} data 旧的翻页数据
 * @param {*} defaultCurrentPage 翻页的初始页码
 * @returns pagination
 */
function transferPagination(data, defaultCurrentPage = 0) {
  return {
    defaultCurrentPage,
    current: data.currentPage + (1 - defaultCurrentPage),
    pageSize: data.numPerPage,
    total: data.totalCount,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: () => `共${data.pageCount || 0}页${
      data.totalCount || 0
    }条`
  };
}
/**
 * antd翻页数据中提取wtdc翻页
 * @param {*} pagination antd pagination
 * @returns
 */
function transferPaginationBack(pagination) {
  return {
    currentPage: pagination.current - (1 - pagination.defaultCurrentPage),
    numPerPage: pagination.pageSize
  };
}

Table.transferPagination = transferPagination;
Table.transferPaginationBack = transferPaginationBack;

export {
  transferPagination,
  transferPaginationBack
};

export default Table;
