import axiosServices from './request';

const DemandService = axiosServices('demand/v1.0');

export default {
  /** 登录 */
  login: DemandService('post', 'operatorAccount/login'),
  /** 修改登录密码 */
  changePassword: DemandService('post', 'operatorAccount/changePassword'),
  /** 忘记密码找回账号 */
  retrieve: DemandService('post', 'operatorAccount/retrieve'),
  /** 获取用户信息 */
  getUserInfo: DemandService('get', 'operatorAccount/info'),
  /** 获取图书分类 */
  bookCatagoryList: DemandService('get', 'userBookMarketing/bookCategoryList'),
  /** 查看留资记录列表 */
  consultationList: DemandService('get', 'operatorAccount/consultationList'),
  /** 处理留资记录 */
  dealConsultation: DemandService('post', 'operatorAccount/dealConsultation'),
  /** 校验图书名称是否已存在 */
  checkDemand: DemandService('post', 'operatorBookMarketing/checkDemand'),
  /** 运营查看图书营销需求统计数据 */
  demandStatistics: DemandService('get', 'operatorBookMarketing/demandStatistics'),
  /** 渠道 */
  channelEntranceList: DemandService('get', 'operatorEntrance/channelEntranceList'),
  /** 物料图书类别 */
  demandBookCategoryList: DemandService('get', 'operatorBookMarketing/bookCategoryList'),
  /** 运营获取图书营销需求列表 */
  demandList: DemandService('get', 'operatorBookMarketing/demandList'),
  /** 运营获取图书营销需求额外物料列表 */
  demandExtraMaterialList: DemandService('get', 'operatorBookMarketing/demandExtraMaterialList'),
  /** 运营查看图书营销需求详情 */
  queryDemandDetail: DemandService('get', 'operatorBookMarketing/demandDetail'),
  /** 根据手机号查询编辑端账号信息 */
  queryByUserPhone: DemandService('get', 'operatorAccount/queryByUserPhone'),
  /** 运营创建图书营销需求 */
  launchDemand: DemandService('post', 'operatorBookMarketing/launchDemand'),
  // 运营查看图书详细信息
  getBookDetailInfo: DemandService('post', 'operatorBookMarketing/getBookDetailInfo'),
  // 根据ISBN填充详细信息
  queryBookInfoByIsbn: DemandService('get', 'operatorBookMarketing/queryBookInfo'),
  // 根据图书征订单提取图书详细信息
  extractBookDetailInfo: DemandService('post', 'operatorBookMarketing/extractBookDetailInfo'),
  fetchExtractedResult: DemandService('get', 'operatorBookMarketing/fetchExtractedResult'),
  /** 运营修改图书征订单、图书样张 */
  updateMaterial: DemandService('post', 'operatorBookMarketing/updateMaterial'),
  /** 运营修改图书详细信息 */
  updateBookDetailInfo: DemandService('post', 'operatorBookMarketing/updateBookDetailInfo'),
  /** 删除运营修改图书物料 */
  deleteExtraMaterials: DemandService('post', 'operatorBookMarketing/deleteExtraMaterials'),
  /** 运营上传图书物料 */
  demandUploadExtraMaterials: DemandService('post', 'operatorBookMarketing/uploadExtraMaterials'),
  /** 替换已上传图书物料 */
  demandReplaceExtraMaterials: DemandService('post', 'operatorBookMarketing/replaceExtraMaterial'),
  /** 运营上传交付物 */
  demandUploadDelivery: DemandService('post', 'operatorBookMarketing/uploadDelivery'),
  /** 运营生成图书营销亮点  */
  generateBookMarketingHighlights: DemandService('post', 'operatorBookMarketing/generateBookMarketingHighlights'),
  getBookMarketingHighlights: DemandService('get', 'operatorBookMarketing/getBookMarketingHighlights'),
  /** 资料检查  */
  checkDemandRequirements: DemandService('get', 'operatorBookMarketing/checkDemandRequirements'),
  /** 开始执行图书扫描任务 */
  demandExecuteTask: DemandService('get', 'operatorBookMarketing/executeTask'),
  /** 运营提交图书营销亮点并开启生成 */
  startGenerating: DemandService('post', 'operatorBookMarketing/startGenerating'),

  // 营销结果物列表
  getGeneratedResult: DemandService('get', 'operatorBookMarketing/getGeneratedResult'),
  /**使用交付物 */
  useAiOutputForDelivery: DemandService('post', 'operatorBookMarketing/useAiOutputForDelivery'),
  /** AI 结果物重新生成 */
  regenerateResult: DemandService('post', 'operatorBookMarketing/regenerateResult'),
  /** 导出AI结果物  */
  exportAdjustedResult: DemandService('post', 'operatorBookMarketing/exportAdjustedResult'),
  /** 运营导出图书营销亮点 */
  exportBookMarketingHighlights: DemandService('post', 'operatorBookMarketing/exportBookMarketingHighlights'),
  /** 应用结果  */
  applyAdjustedResult: DemandService('post', 'operatorBookMarketing/applyAdjustedResult')
};
