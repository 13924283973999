/**
 * Date    : 2021/11/16
 * Author  : weiLin
 * Declare : index
 */

import React from 'react';
import { Spin } from '@/components/AntD';

import './index.scss';
import classNames from 'classnames';

export default function Loading(props) {

  const { className, theme, computedMatch, location, ...restProps } = props;

  return (
    <div className={classNames('Loading', className)}>
      {theme === THEME.default && <Spin {...restProps} />}
      {theme === THEME.rays && <div className="rays-loading">
        <div className="rays-loading-rect" />
      </div>}
      {theme === THEME.rig && <div className="rig-loading" />}
    </div>
  );
}

const THEME = {
  default: 'default',
  rays: 'rays', //小睿形象
  rig: 'rig' //圆环变换
};

Loading.defaultProps = {
  theme: THEME.default
};

