import { Image as AImage } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import ERROR_IMG from '@/assets/images/default_img.png';
import Utils from '@/utils/utils';

import './index.scss';

export default function(props) {
  const { fallback, type } = props;
  const [url, setUrl] = useState('');
  const src = useMemo(() => {
    if(props.src && props.src.includes('x-oss-process')) {
      return props.src;
    }
    return Utils.getImg(props.src, 0, props.effects);
  }, [props.src, props.effects]);

  const preview = useMemo(() => {
    if(props.preview) {
      let opt = { src: Utils.getImg(props.src, 0) };
      if(props.preview !== true) {
        Object.assign(opt, props.preview);
      }
      return opt;
    } else {
      return false;
    }
  }, [props.preview, src]);

  useEffect(() => {
    let imgData = new Image();
    imgData.onload = () => {
      setUrl(src);
    };
    imgData.onerror = () => {
      setUrl(props.src?.split('?')[0] || fallback || ERROR_IMG);
    };
    imgData.src = src;
  }, [src]);

  return type === 'topic' ?
    <AImage fallback={ERROR_IMG} {...props} src={url} preview={preview} />
    : (url && <AImage fallback={ERROR_IMG} {...props} src={url} preview={preview} />);
}
