import { notification } from 'antd';

import './index.scss';

// 初始化加载
notification.config({
  placement: 'bottomRight',
  bottom: 41,
  duration: 3
});

export default notification;
