
/** localStorage keys */
export const LOCAL_STORAGE_KEYS = {
  /** AI调优 临时数据 */
  AI_ADJUST_CONTENT: 'AI_ADJUST_CONTENT',
  /** AI产出 临时数据 */
  AI_OUTPUT_CONTENT: 'AI_OUTPUT_CONTENT',
  TOKEN: 'TOKEN'
};

export const SESSION_STORAGE_KEYS = {
  VERISON_TIP_TIMESTAMP: 'VERISON_TIP_TIMESTAMP'
};
