
export default {
  // SIDE_BAR_HIDDEN: 1200, // 默认收起
  SIDE_BAR_HIDDEN: 1430, // 默认收起
  SIDE_BAR_WIDTH: 180, //展开
  MIN_SIDEBAR_WIDTH: 52, //收起
  HEADER_HEIGHT: 48, // header
  /** 不展示左侧栏的页面 */
  HIDE_SIDEBAR_PAGES: [
  ],
  HIDE_HEADER_PAGES: [
  ],
  MENU_CHILD_MAP: {
    // '/rays7/ai/review/manage': '/rays7/ai/tools'
  }
};
