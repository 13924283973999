
export const isDevelopment = process.env.NODE_ENV !== 'production';

const hostIP = isDevelopment ? 'localhost' : window.location.hostname; //STEP2
const publicDomain = isDevelopment ? 'rays7.raysgo.com' : hostIP;
const protocol = isDevelopment ? 'http:' : window.location.protocol;
const domainArr = ['raysgo.com', '5rs.me'];
const envArr = ['TEST', 'PRO'];
const envIndex = domainArr.findIndex(v => publicDomain.indexOf(v) > -1); // 0测试环境，1生产环境;
const adviserDomain = ['raysgo.com', '5rs.me'][envIndex !== -1 ? envIndex : 1];
export const BASE_API_ROOT = window.location.origin + (isDevelopment ? '/api' : '');

// 系统角色
const systemCode = 'book_voyage_channel';

// 系统 ID
const systemId = '1';

const config = {
  isDevelopment,
  publicDomain,
  systemCode,
  adviserDomain,
  industryCaseId: systemId,
  systemNameText: '运营管理系统',
  COOKIE: `${systemCode}_token`,
  env: envArr[envIndex],
  PRODUCTION_COOKIE: `${systemCode}_production_id`,
  iconfontCSS: '//at.alicdn.com/t/c/font_3953416_ibzq6ifi6j.css',
  iconfontURL: [
    '//at.alicdn.com/t/c/font_3953416_ibzq6ifi6j.js',
    '//at.alicdn.com/t/font_3253342_b5pehidaty.js' // 知识商城
  ],     // 字体图标库链接
  appId: 'wxbc22f60a2990a1e5', // 微信开放平台网站应用appId
  protocol,
  isJournal: publicDomain === 'raysabc.com'
};

export default config;
